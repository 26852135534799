// 账号状态
export const accountStatusTextMap = new Map([
  [1, '正常'],
  [0, '冻结']
]);

// 账号状态
export const accountStatusColorMap = new Map([
  [1, 'success'],
  [0, 'info']
]);

// 在职状态
export const onJobTextMap = new Map([
  [1, '在职'],
  [0, '离职']
]);

// 在职状态
export const onJobColorMap = new Map([
  [1, 'success'],
  [2, 'info']
]);
