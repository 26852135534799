import api from './request';

// ---- 账号审批模块
// 查询申请列表
export const getApplyList = (data: {
  userName?: string;
  mobile?: string;
  departmentId?: number;
  page?: Basic.Page;
}) => api.post<Approval.ListData>('/auth-admin/user/approveQuery', data);

// 审批
export const approval = (data: { uid: number; result: number }) =>
  api.post('/auth-admin/user/approve', data);

// ---- 用户模块
//  列表
export const getUserList = (data: {
  userName?: string;
  mobile?: string;
  departmentId?: number;
  status?: number;
  page?: Basic.Page;
}) => api.post<User.ListData>('/auth-admin/user/query', data);

// 更新/新增
export const createOrUpdateUser = (data: User.UserItem) =>
  api.post('/auth-admin/user/createOrUpdate', data);

// 用户详情
export const getUserDetail = (userId: number) =>
  api.post<{ user: User.UserItem }>('auth-admin/user/info', { userId });

// 根据手机号查询零售用户中心用户
export const getBPUser = (data: { mobile: string; accountType: number; page?: Basic.Page }) =>
  api.post<{
    page: Basic.PageResponse;
    userInfoList: User.BpUser[];
  }>('user-admin/userService/getBPUser', data);

// 批量上传角色权限
export const exportBindRole = (fileUrl: string) =>
  api.post<any>('auth-admin/user/exportBindRole', { fileUrl });
